import Link from 'next/link';
import { Button } from '../components/inputs/Button';
import { Stack } from '../components/layout/Stack';
import { Meta } from '../components/Meta';
import { Text } from '../components/typography/Text';
import { styled } from '../stitches.config';

export default function Custom404() {
  return (
    <>
      <Meta title="Page not found" />
      <Content>
        <Stack spacing="large" align="center">
          <Text size="xxlarge">404</Text>
          <Text size="large" weight="semibold" align="center">
            Sorry, we couldn&apos;t find what you were looking for.
          </Text>
          <Link href="/" passHref legacyBehavior>
            <Button as="a">Return to home</Button>
          </Link>
        </Stack>
      </Content>
    </>
  );
}

const Content = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
});
